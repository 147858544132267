export function blockHeroSliderScript() {
    document.addEventListener('DOMContentLoaded', function () {
        const heroSliders = document.querySelectorAll('.blockHeroSlider__slider');

        if (heroSliders) {
            heroSliders.forEach(singleHeroSlider => {
                let heroesSlider = new window.splide(singleHeroSlider, {
                    type: 'loop',
                    perPage: 1,
                    perMove: 1,
                    center: 'true',
                    pagination: true,
                    arrows: false,
                    autoplay: false,
                    interval: 5000,
                    pauseOnHover: true,
                });
                heroesSlider.mount();
            })
        }
    })
}