import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

export function blockLogoSliderScript() {
    document.addEventListener('DOMContentLoaded', function () {
        const sliderLogos = document.querySelectorAll('.blockSliderLogo__slider');

        if (sliderLogos) {
            sliderLogos.forEach(singleLogoSlider => {
                let sliderLogo = new window.splide(singleLogoSlider, {
                    type: 'loop',
                    perPage: 5,
                    arrows: false,
                    gap: 12,
                    drag: 'free',
                    focus: 'center',
                    pagination: false,
                    breakpoints: {
                        1100: {
                            perPage: 4,
                        },
                        780: {
                            perPage: 3,
                        },
                        630: {
                            perPage: 2,
                        },
                        420: {
                            perPage: 1,
                        },
                    },
                    autoScroll: {
                        speed: 0.5,
                        pauseOnHover: false,
                    },
                });
                sliderLogo.mount({ AutoScroll });
            })
        }
    })
}