import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function blockBlogPostScript() {

    document.addEventListener('DOMContentLoaded', function() {
        new Splide('#carousel', {
            type: 'carousel',
            perPage: 1,
            autoplay: false,
            interval: 3000,
            pauseOnHover: false,
            pagination: true,
            paginationType: 'bullets',
            arrows: false
        }).mount();
    });
      
}
