import axios from "axios";
export function navInit() {

  const menuMobile = document.querySelector('.headerMobile');

  if (menuMobile) {
    const burgerBtn = document.querySelector('.headerMobile__hamburger svg');
    const menu = menuMobile.querySelector('.menuMobile');
    const menuWrapper = menu.querySelector('.menuMobile__wrapper');
    const closeBtn = document.querySelector('.menuMobile__close');
    const mainMenu = menu.querySelector('.menu_mobile');
    const subMenus = menu.querySelectorAll(`.menu_mobile__submenu`);

    if (mainMenu) {
      mainMenu.querySelectorAll('li').forEach(menuItem => {
        let btn = menuItem.querySelector('span.btn_open_menu');
        if (btn) {
          btn.addEventListener('click', () => {
            let target = btn.getAttribute('data-open-submenu');
            let submenu = document.querySelector(`.menu_mobile__submenu[data-submenu=${target}]`);
            if (submenu) {
              submenu.classList.add('opened');
              menuWrapper.style.height = `${submenu.clientHeight + 1}px`;
            }
          });
        }
      });
    }
    if (subMenus) {
      subMenus.forEach(submenu => {
        let close = submenu.querySelector('.btn_back');
        close.addEventListener('click', () => {
          submenu.classList.remove('opened');
        });
      });
      menuWrapper.querySelectorAll('li').forEach(menuItem => {
        let btn = menuItem.querySelector('span.btn_open_menu');
        if (btn) {
          btn.addEventListener('click', () => {
            let target = btn.getAttribute('data-open-submenu');
            let submenu = document.querySelector(`.menu_mobile__submenu[data-submenu=${target}]`);
            if (submenu) {
              submenu.classList.add('opened');
              menuWrapper.style.height = `${submenu.clientHeight + 1}px`;
            }
          });
        }
      });

      menuWrapper.querySelectorAll('li').forEach(menuItem => {
        let btnClose = menuItem.querySelector('.menuMobile__close');
        if (btnClose) {
          btnClose.addEventListener('click', () => {

            menu.classList.remove('open');
            subMenus.forEach(submenu => {
              submenu.classList.remove('opened');
            });

          });
        }
      });

    }

    burgerBtn.addEventListener('click', () => {
      menu.classList.add('open');
    });

    closeBtn.addEventListener('click', () => {
      menu.classList.remove('open');
      setTimeout(() => {
        if (subMenus) {
          subMenus.forEach(submenu => {
            submenu.classList.remove('opened');
          });
        }
      }, 500);
    });
  }

  //ajax add to cart
  window.addEventListener("DOMContentLoaded", () => {
    const addToCartEvent = () => {
      const addToCartButtons = document.querySelectorAll(".add-to-cart");
      for (const addToCart of addToCartButtons) {
        addToCart.onclick = () => {
          addToCart.classList.add("loading");
          addToCart.textContent = "";
          window.axios
            .get(addToCart.dataset.url)
            .then(() => {
              addToCart.classList.remove("loading");
              addToCart.classList.add("loaded");
              location.reload();
            })
            .catch(e => {
              if (e.response.status !== 400) {
                addToCart.classList.remove("loading");
                addToCart.classList.add("failed");
                location.reload();
              }
            })
            .finally(() => {
              location.reload();
            });
        };
      }
    };
    window.addEventListener(
      "load",
      function () {
        addToCartEvent();
        // document.addEventListener("DOMNodeInserted", addToCartEvent, false);
      },
      false
    );
    document.onpropertychange = addToCartEvent;
  });

  //perform ajax request to dynamically change quantity in mini cart
  const minicartMore = document.querySelectorAll(".product-quantity .more");
  const minicartLess = document.querySelectorAll(".product-quantity .less");
  const totalNode = document.querySelector(".mini-cart-total");
  const discountNode = document.querySelector(".mini-cart-total-discount");
  for (const more of minicartMore) {
    more.addEventListener("click", () => {
      const quantity = more.parentNode.dataset.quantity;
      const id = more.parentNode.dataset.id;
      window.axios
        .get(
          `${ajax.url}?action=increase_product_quantity&id=${id}&quantity=${quantity}`
        )
        .then(response => {
          const data = response.data;
          if (data.quantity) {
            more.parentNode.parentNode.firstElementChild.innerText =
              data.quantity;
            more.parentNode.dataset.quantity = data.quantity;
            // cartCount.innerText = Number(cartCount.innerText) + 1;
            totalNode.innerText = data.total + " PLN";
            if (data.discount && discountNode) {
              discountNode.innerText = data.discount + " PLN";
            }
          }
        });
    });
  }
  for (const less of minicartLess) {
    less.addEventListener("click", () => {
      const quantity = less.parentNode.dataset.quantity;
      const id = less.parentNode.dataset.id;
      window.axios
        .get(
          `${ajax.url}?action=decrease_product_quantity&id=${id}&quantity=${quantity}`
        )
        .then(response => {
          const data = response.data;
          if (data.quantity) {
            if (quantity !== data.quantity) {
              less.parentNode.parentNode.firstElementChild.innerText =
                data.quantity;
              less.parentNode.dataset.quantity = data.quantity;
              // cartCount.innerText = Number(cartCount.innerText) - 1;
            }
            totalNode.innerText = data.total + " PLN";
            if (data.discount && discountNode) {
              discountNode.innerText = data.discount + " PLN";
            }
          }
        });
    });
  }

  const elements = document.querySelectorAll('.menu_mobile li.menu-item-has-children');

  elements.forEach(element => {
    const item = element.querySelector('a');
    item.appendChild(document.createElement("span"));

    item.querySelector('span').addEventListener("click", (e) => {
      e.preventDefault();
      if (item.classList.contains('open')) {
        item.classList.remove('open');
        element.querySelector('.sub-menu').classList.remove('open');
      } else {
        item.classList.add('open');
        element.querySelector('.sub-menu').classList.add('open');
      }
    })
  })
}

export const initSearchModal = () => {

  const searchButtons = document.querySelectorAll('.menu__search');
  const searchModal = document.querySelector('.searchModal');

  const htmlTag = document.querySelector('html');
  const bodyTag = document.querySelector('body');

  if (searchButtons && searchModal) {
    searchButtons.forEach(searchButton => {
      searchButton.addEventListener('click', e => {
        e.preventDefault();
        if (searchModal.classList.contains('active')) {
          searchModal.classList.remove('active');
          htmlTag.classList.remove('overflow-hidden');
          bodyTag.classList.remove('overflow-hidden');
        } else {
          searchModal.classList.add('active');
          htmlTag.classList.add('overflow-hidden');
          bodyTag.classList.add('overflow-hidden');
        }
      });
    })

    const closeModal = searchModal.querySelector('.close');
    closeModal.addEventListener('click', e => {
      e.preventDefault();
      searchModal.classList.remove('active');
      htmlTag.classList.remove('overflow-hidden');
      bodyTag.classList.remove('overflow-hidden');
    });

    const modalForm = searchModal.querySelector('form');
    const searchInput = searchModal.querySelector('input');
    const searchResults = searchModal.querySelector('.searchModal__results');
    const searchLoading = searchModal.querySelector('.searchModal__loading');
    modalForm.addEventListener('submit', e => {
      e.preventDefault();
      if (searchInput.value.length > 0) window.location = `${window.location.origin}/?s=${searchInput.value}&post_type=product`;
    });

    let timeoutId;
    searchInput.addEventListener('input', () => {
      searchLoading.classList.remove('active');
      searchModal.classList.remove('large');
      clearTimeout(timeoutId);
      if (searchInput.value.length > 3) {
        timeoutId = setTimeout(() => {
          searchResults.innerHTML = "";
          searchLoading.classList.add('active');
          searchModal.classList.add('active');
          axios.post(`/wp-json/victoria/v1/search`, {
            search: searchInput.value
          }).then(response => {
            searchResults.innerHTML = response.data.html;
            searchLoading.classList.remove('active');
            searchModal.classList.add('large');
          });
        }, 500);
      } else {
        searchResults.innerHTML = "";
        searchModal.classList.remove('large');
      }
    });
  }
}