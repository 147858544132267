export const initCustomPlaceholder = () => {

    if (document.getElementById("pa_krzywizna")) { 
        var curvature = document.getElementById('pa_krzywizna');
        curvature.options[curvature.selectedIndex].innerHTML = "Wybierz";
    }

    if (document.getElementById("pa_krzywizna")) { 
        var power = document.getElementById('pa_moc');
        power.options[power.selectedIndex].innerHTML = "Wybierz";
        }
    
}