export const initMiniCart = () => {
    document.addEventListener('DOMContentLoaded', function () {

        function delegate_event(event_type, ancestor_element, target_element_selector, listener_function) {
            ancestor_element.addEventListener(event_type, function (event) {
                if (event.target && event.target.matches && event.target.matches(target_element_selector)) {
                    (listener_function)(event);
                }
            });
        }

        const cartCloseBtn = document.querySelector('.sideMiniCart__close');
        const sideMiniCart = document.querySelector('.sideMiniCart');
        const sideMiniCartBg = document.querySelector('.sideMiniCart__background');
        const htmlTag = document.querySelector('html');
        const bodyTag = document.querySelector('body');
        const continueShoppingBtn = document.querySelector('.woocommerce-mini-cart__continueBtn');

        const closeMiniCart = () => {
            sideMiniCart.classList.remove('open');
            htmlTag.classList.remove('overflow-hidden');
            bodyTag.classList.remove('overflow-hidden');
        };

        delegate_event('click', document, '.sideMiniCart__close', closeMiniCart);
        delegate_event('click', document, '.woocommerce-mini-cart__continueBtn', closeMiniCart);
        
        if (cartCloseBtn) cartCloseBtn.addEventListener('click', closeMiniCart);
        if (sideMiniCartBg) sideMiniCartBg.addEventListener('click', closeMiniCart);
        if (continueShoppingBtn) continueShoppingBtn.addEventListener('click', closeMiniCart);
    });
}