export const initReadmore = () => {
    const readmoreBlocks = document.querySelectorAll('.blockReadmore');

    if (readmoreBlocks.length > 0) {
        readmoreBlocks.forEach(item => {
            const btn = item.querySelector('.blockReadmore__btn');
            const moreContent = item.querySelector('.blockReadmore__wysiwyg-more');

            if (!moreContent) return;

            btn.addEventListener('click', () => {
                // moreContent.classList.toggle('active');
                // btn.classList.add('hidden');

                if (moreContent.classList.contains('active')) {
                    moreContent.classList.remove('active');
                    btn.textContent = 'czytaj więcej';
                } else {
                    moreContent.classList.add('active');
                    btn.textContent = 'zwiń';
                }
            });
        });
    }
}