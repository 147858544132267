export const productInquiryFormModalInit = () => {
  document.addEventListener('DOMContentLoaded', function () {
    const body = document.querySelector('body');
    const openModalBtn = document.querySelector('.inquiry-form-open');
    const closeModalBtn = document.querySelector('#productInquiryFormModalClose');
    const inquiryFormModal = document.querySelector('.productInquiryFormModal');
    const productTitle = document.querySelector('.customProductPage__title h1');
    const productSku = document.querySelector('.customProductPage__sku');
    const inquiryForm = document.querySelector('.productInquiryFormModal__form');
    const productTitleField = inquiryForm.querySelector('#productTitleField');
    const productSkuField = inquiryForm.querySelector('#productSkuField');

    if(inquiryFormModal && openModalBtn) {
      openModalBtn.addEventListener('click', (e) => {
        productTitleField.value = productTitle.innerText;
        productSkuField.value = productSku.innerText;
        inquiryFormModal.showModal();
        body.style.overflow = 'hidden';
      });
    }

    if(inquiryFormModal && closeModalBtn) {
      closeModalBtn.addEventListener('click', (e) => {
        inquiryFormModal.close();
        body.style.overflow = 'auto';
      });
    }
  });
}