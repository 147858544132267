export const displayProductVariationInfo = () => {
  document.addEventListener('DOMContentLoaded', function () {
    const customProductPage = document.querySelector('.customProductPage');
    const variationId = customProductPage.querySelector('.variation_id');
    const openModalBtn = document.querySelector('.inquiry-form-open');
    const variationsForm = document.querySelector('.variations_form');
    const productInfo = document.querySelector('.variable-stock');

    if (variationsForm && productInfo) {
      jQuery('.single_variation_wrap').on('show_variation', function (event, variation) {
        if(customProductPage.classList.contains('product-type-variable') && variationId.value != 0) {
          openModalBtn.classList.remove('disabled');
        }

        if(productInfo) {
          const availabilityInfo = variation.availability_html;
          const productSku = document.querySelector('.customProductPage__sku');
  
          productInfo.innerHTML = '';
          productInfo.classList.remove('availability-form');
          productSku.innerHTML = '';
          productSku.classList.add('sku-empty');
  
          if(availabilityInfo.includes('cwginstock-subscribe-form')) {
            productInfo.classList.add('availability-form');
          }
  
          if(variation.sku) {
            productSku.innerHTML = variation.sku;
            productSku.classList.remove('sku-empty');
          }

          productInfo.innerHTML = variation.availability_html;
        }
      });
    }
  });
}