export function checkoutRadioBtns() {

    window.addEventListener("DOMContentLoaded", () => {

        jQuery(document).ready(function (jQuery) {

            jQuery(document.body).on('updated_checkout', function () {
                jQuery('#shipping_method li').removeClass('active');
                let selectedMethodInput = jQuery('input[name^="shipping_method"]:checked');

                if (selectedMethodInput.length) {
                    selectedMethodInput.closest('li').addClass('active');
                }
                jQuery('input[name="payment_method"]').on('change', function () {
                    console.log('ddd');
                    jQuery('.wc_payment_methods li').removeClass('active');
                    jQuery(this).closest('li').addClass('active');
                });
                addActiveClassOnLoad();
            });

            function addActiveClassOnLoad() {
                let selectedPaymentInput = jQuery('input[name="payment_method"]:checked');
                if (selectedPaymentInput.length) {
                    selectedPaymentInput.closest('li').addClass('active');
                }
            }

        });

    });

}