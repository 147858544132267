export const initAddToCartAjax = () => {
    document.addEventListener('DOMContentLoaded', function () {
        const button = document.querySelector('.single_add_to_cart_button');
        const sideMiniCart = document.querySelector('.sideMiniCart');
        const htmlEl = document.querySelector('html');
        const bodyEl = document.querySelector('body');

        if (!button) {
            return;
        }
        
        button.addEventListener('click', function (event) {
            if(event.target.classList.contains('disabled')) {
                return;
            }

            event.preventDefault();

            let data;
            const thisbutton = event.target;
            const form = thisbutton.closest('form.cart');
            const id = thisbutton.value;
            let product_qty = form.querySelector('input[name=quantity]').value || 1;
            let product_id = form.querySelector('input[name=product_id]') ? form.querySelector('input[name=product_id]').value : id;
            let variation_id = form.querySelector('input[name=variation_id]') ? form.querySelector('input[name=variation_id]').value : 0;

            data = {
                action: 'woocommerce_ajax_add_to_cart',
                product_id: product_id,
                product_sku: '',
                quantity: product_qty,
                variation_id: variation_id,
            };

            document.body.dispatchEvent(new CustomEvent('adding_to_cart', {
                detail: [thisbutton, data]
            }));

            const xhr = new XMLHttpRequest();
            xhr.open('POST', wc_add_to_cart_params.ajax_url);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onloadstart = function () {
                thisbutton.classList.add('loading');
            };
            xhr.onload = function () {
                thisbutton.classList.remove('loading');
                const response = JSON.parse(xhr.responseText);
                if (response.error && response.product_url) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'Wystąpił błąd.',
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 3000
                    });
                } else {
                    htmlEl.classList.add('overflow-hidden');
                    bodyEl.classList.add('overflow-hidden');
                    sideMiniCart.classList.add('open');
                    sideMiniCart.classList.add('cartLoader');

                    const event_wc_fragment_refresh = new Event('wc_fragment_refresh');
                    document.body.dispatchEvent(event_wc_fragment_refresh);
                }
            };
            xhr.send(new URLSearchParams(data).toString());
            return false;
        });

        jQuery(document.body).on('removed_from_cart', function () {
            sideMiniCart.classList.remove('cartLoader');
        });

    });

}