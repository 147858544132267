export const initFaq = () => {
    const faqBlocks = document.querySelectorAll('.blockFaq');

    if (faqBlocks) {
        faqBlocks.forEach(faq => {
            const faqItems = faq.querySelectorAll('.blockFaq__single');

            if(!faqItems) return;
            faqItems.forEach(faqItem => {
                const opener = faqItem.querySelector('.blockFaq__title');
                const content = faqItem.querySelector('.blockFaq__content');

                if (opener) {
                    opener.addEventListener('click', () => {
                        faqItem.classList.toggle('active');
                        if (content.style.maxHeight) content.style.maxHeight = null;
                        else content.style.maxHeight = `${(content.scrollHeight + 500)}px`;
                        if (content.style.marginTop) content.style.marginTop = null;
                        else content.style.marginTop = `12px`;
                    })
                }
            })
        })
    }
}