export function homesiteSliders() {
  document.addEventListener( 'DOMContentLoaded', function () {

    if(document.querySelector('.sectionBannerHomesiteSlider__slider')) {
      var homesiteSlider = new window.splide( '.sectionBannerHomesiteSlider__slider', {
      type       : 'loop',
      perPage : 1,
      autoplay : true,
      interval : 3000,
      pagination : true,
      arrows     : false,
      } ).mount();
    }

    if(document.querySelector('.sectionBannerSlider__slider')) {
      var homesiteSlider2 = new window.splide( '.sectionBannerSlider__slider', {
      type       : 'loop',
      perPage : 1,
      autoplay : true,
      interval : 3000,
      pagination : true,
      arrows     : false,
      } ).mount();
    }
  });
}

