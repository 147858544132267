export const headingDecorations = () => {
    const decorations = document.querySelectorAll('.decorations');

    if(decorations){
        
        decorations.forEach(decoration => {
            let text = decoration.innerHTML;
            decoration.innerHTML = "<span>" + text + "</span>";
        })
    }
}