export function blockWorkersListScript() {
    document.addEventListener('DOMContentLoaded', function () {
        const workersList = document.querySelectorAll('.blockWorkersList__slider');

        if (workersList) {
            workersList.forEach(employee => {
                const splide = new window.splide(employee, {
                    gap: '24px',
                    pagination: true,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    mediaQuery: 'min',
                    autoWidth: true,
                    breakpoints: {
                        992: {
                            destroy: true,
                        }
                    }
                });
                splide.mount();
            })
        }
    })
}