export const contactFormModalInit = () => {
  document.addEventListener('DOMContentLoaded', function () {
    const body = document.querySelector('body');
    const productConfiguratorModal = document.querySelector('.productConfiguratorModal');

    if(productConfiguratorModal) {
      const openModalBtn = productConfiguratorModal.querySelector('.help');
      const closeModalBtn = document.querySelector('#contactFormModalClose');
      const contactFormModal = document.querySelector('.contactFormModal');

      if(contactFormModal && openModalBtn) {
        openModalBtn.addEventListener('click', (e) => {
          window.configurator.root.close();
          document.body.classList.remove('productConfigurator--opened');

          contactFormModal.showModal();
          body.style.overflow = 'hidden';
        });
      }
  
      if(contactFormModal && closeModalBtn) {
        closeModalBtn.addEventListener('click', (e) => {
          contactFormModal.close();
          body.style.overflow = 'auto';
        });
      }
    }
  });
}