export function productCustomGallery() {
    let main = new window.splide('.splidePrimary', {
      type: 'fade',
      rewind: true,
      pagination: false,
      arrows: true,
    });
  
    // let thumbnails = new window.splide('.splideNav', {
    //   fixedWidth: 240,
    //   fixedHeight: 180,
    //   gap: 24,
    //   rewind: true,
    //   pagination: false,
    //   arrows: false,
    //   isNavigation: true,
    //   breakpoints: {
    //     800: {
    //         fixedWidth: 160,
    //         fixedHeight: 120,
    //     },
    //     500: {
    //         fixedWidth: 101,
    //         fixedHeight: 76,
    //     },
    //   },
    // });
  
    // main.sync(thumbnails);
    main.mount();
    // thumbnails.mount();
  
    new window.lightbox({
      elements: '.lightBoxRun'
    });

    const variationsForm = document.querySelector('.variations_form');

    if (variationsForm) {
        jQuery('.single_variation_wrap').on('show_variation', function (event, variation) {
            if(!window.product_gallery[variation.variation_id]) return;

            if(main.length > 0){
                while(main.length){
                    main.remove(main.length - 1)
                }
            }

            window.product_gallery[variation.variation_id].forEach(item => {
                main.add(`<li class="splide__slide productCustomGallery__images"><a class="lightBoxRun" href="${item.image_url}"><img src="${item.thumbnail}"></a></li>`);
            });

            new window.lightbox({
                elements: '.lightBoxRun'
            });
        })
    }
}