export const initCustomCollapses = () => {
    const collapses = document.querySelectorAll('.customCollapse');

    if (collapses) {
        const hideAllCollapses = (current) => {
            collapses.forEach(collapse => {
                if (collapse === current) return;
                let content = collapse.querySelector(':scope > .customCollapse__content');
                collapse.classList.remove('opened');
                content.style.maxHeight = null;
            })
        }

        collapses.forEach(collapse => {
            try {
                let toggle = collapse.querySelector(':scope > .customCollapse__title');
                let content = collapse.querySelector(':scope > .customCollapse__content');

                if (!toggle || !content) return;

                toggle.addEventListener('click', () => {
                    if (!collapse.classList.contains('no-hide-hierarchy')) {
                        hideAllCollapses(collapse);
                    }
                    collapse.classList.toggle('opened');
                    content.style.maxHeight = content.style.maxHeight ? null : content.scrollHeight + "px";
                });

                if (collapse.classList.contains('opened')) {
                    hideAllCollapses(null);
                    collapse.classList.toggle('opened');
                    content.style.maxHeight = content.style.maxHeight ? null : content.scrollHeight + "px";
                }
            } catch (exception) {
                console.error(exception);
            }
        })
    }
}