export function categorySelect() {

    window.addEventListener("DOMContentLoaded", () => {

        const categorySelect = document.querySelectorAll(".selectCustomItem");

        if (!categorySelect) return;

        categorySelect.forEach(element => {
            element.addEventListener("change", (event) => {
                const selectedOption = event.target.value;
                window.location.href = selectedOption;
            });
        })
    });

}