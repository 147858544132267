const customArchiveProductEvents = () => {
  document.querySelectorAll('.current-cat-ancestor').forEach(element=>{
    element.querySelector('a').classList.add('open');
    element.querySelector('.children').classList.add('open');
  })
  const elements = document.querySelectorAll('.customArchiveProduct__categoryList li.has_children');
  // console.log(elements);

  elements.forEach(element => {
    const item = element.querySelector('a');
    item.appendChild(document.createElement("span"));

    item.querySelector('span').addEventListener("click", (e)=>{
      e.preventDefault();
      if(item.classList.contains('open')) {
        item.classList.remove('open');
        element.querySelector('.children').classList.remove('open');
      } else {
        item.classList.add('open');
        element.querySelector('.children').classList.add('open');
      }
    })
  })
}

export const customArchiveProductScripts = () => {
  document.addEventListener( 'DOMContentLoaded', function () {
    customArchiveProductEvents();
  })
}