import Splide from '@splidejs/splide';

window.splide = Splide;
window.lightbox = require('simple-lightbox');
window.axios = require("axios");

import { initSearchModal, navInit } from './modules/navigations';
import { homesiteSliders } from './modules/homesiteSliders';
import { productCustomGallery } from './modules/productCustomGallery';
import { productCardSlider } from './modules/productCardSlider';
import { productDescriptionScript } from './modules/productDescriptionScript';
import { customArchiveProductScripts } from './modules/customArchiveProductScripts';
import { blockProductSliderScript } from "../../includes/blocks/block-product-slider/script";
import { blockHeroSliderScript } from "../../includes/blocks/block-hero/scripts";
import { blockLogoSliderScript } from "../../includes/blocks/block-slider-logo/script";
import { blockBlogPostScript } from "../../includes/blocks/block-blog-post/script";
import { blockWorkersListScript } from "../../includes/blocks/block-workers-list/script";
import { initReadmore } from "../../includes/blocks/block-readmore/script";
import { initCustomCollapses } from "./modules/customCollapses";
import { initCustomPlaceholder } from "./modules/customProductPage";
import { initFaq } from "../../includes/blocks/block-faq/script";
import { categorySelect } from "./modules/categorySelect";
import fontawesome from '@fortawesome/fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas, faOtter } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { initMiniCart } from "./modules/miniCart";
import { initAddToCartAjax } from "./modules/addToCart";
import { headingDecorations } from "./modules/decorations";
import { displayProductVariationInfo } from "./modules/productVariations";
import { contactFormModalInit } from "./modules/contactFormModal";
import { productInquiryFormModalInit } from "./modules/productInquiryFormModal";
import Corrective_lens from "./modules/corrective_lens";
import { checkoutRadioBtns } from "./modules/checkoutRadioBtns";

if (document.querySelector('.headerMobile, .headerDesktop')) {
  fontawesome.library.add(fab, fas, far);
}

navInit();
homesiteSliders();
productCardSlider();
Corrective_lens();

if (document.querySelector('.productCustomGallery')) {
  productCustomGallery();
}

if (document.querySelector('#wooProductDescription__wrapper')) {
  productDescriptionScript();
}

if (document.querySelector('.customArchiveProduct')) {
  customArchiveProductScripts();
}

if (document.querySelector('.blockProductSlider')) {
  blockProductSliderScript();
}

if (document.querySelector('.blockHeroSlider')) {
  blockHeroSliderScript();
}

if (document.querySelector('.blockSliderLogo__slider')) {
  blockLogoSliderScript();
}

if (document.querySelector('.blockBlogPost__slider')) {
  blockBlogPostScript();
}

if (document.querySelector('.blockFaq')) {
  initFaq();
}

if (document.querySelector('.blogPage')) {
  categorySelect();
}

if (document.querySelector('.single_add_to_cart_button')) {
  initAddToCartAjax();
  initMiniCart();
}

if (document.querySelector('.blockWorkersList__slider')) {
  blockWorkersListScript();
}

if (document.querySelector(".customCollapse")) {
  initCustomCollapses();
}

if (document.querySelector(".variations select")) {
  initCustomPlaceholder();
}

if (document.querySelector('.searchModal')) {
  initSearchModal();
}

if (document.querySelector('.decorations')) {
  headingDecorations();
}

if (document.querySelector('.customProductPage')) {
  displayProductVariationInfo();
  contactFormModalInit();
  productInquiryFormModalInit();
}

if (document.querySelector('#shipping_method')) {
  checkoutRadioBtns();
}


if (document.querySelector('.blockReadmore')) {
  initReadmore();
}
//change cart quantity
function incrementCart() {
  const updateButton = document.querySelector(".actions>button");

  this.parentNode.querySelector(`input.text`).stepUp();

  if (updateButton) {
    updateButton.disabled = false;
    updateButton.click();
  }
}
function decrementCart() {
  const updateButton = document.querySelector(".actions>button");

  this.parentNode.querySelector(`input.text`).stepDown();

  if (updateButton) {
    updateButton.disabled = false;
    updateButton.click();
  }
}
function listenOnCart() {
  const arrowsUp = document.querySelectorAll(".qty_button.plus");
  const arrowsDown = document.querySelectorAll(".qty_button.minus");
  for (const arrow of arrowsUp) {
    arrow.onclick = incrementCart;
  }
  for (const arrow of arrowsDown) {
    arrow.onclick = decrementCart;
  }
}
listenOnCart();
const config = { attributes: true, childList: true, subtree: true };
const observer = new MutationObserver(listenOnCart);
observer.observe(document, config);


/**
 * Disable WPCF7 button while it's submitting
 * Stops duplicate enquiries coming through
 */
if (document.querySelector('form.wpcf7-form')) {
  const formCF7 = document.querySelector('form.wpcf7-form');
  document.addEventListener('wpcf7submit', function (event) {

    // find only disbaled submit buttons
    const button = formCF7.querySelector('.wpcf7-submit[disabled]');

    // grab the old value
    const old_value = button.getAttribute('data-value');

    // enable the button
    button.removeAttribute('disabled');

    // put the old value back in
    button.value = old_value;

  }, false);

  formCF7.addEventListener('submit', function () {
    if (formCF7.querySelector('.ajax-loader')) formCF7.querySelector('.ajax-loader').remove();

    const button = formCF7.querySelector('input[type=submit]');
    const current_val = button.value;

    // store the current value so we can reset it later
    button.setAttribute('data-value', current_val);

    // disable the button
    button.setAttribute("disabled", true);

    // tell the user what's happening
    button.value = "Wysyłanie...";

  });
}
