export default () => {
    const correctiveForm = document.querySelector('.corrective_lens');
    const correctiveTable = document.querySelector('.corrective_lens table');

    if(!correctiveForm || !correctiveTable) return;

    const hiddens = correctiveForm.querySelectorAll('input[type=hidden]');
    const selects = correctiveForm.querySelectorAll('select');
    if(!hiddens || !selects) return;

    const selected = [];
    const event = new Event('changelens');

    selects.forEach(select => {
        select.addEventListener('change', () => {
            let found = selected.findIndex(element => element.name === select.name.replace('lens_', ''));
            if(found > -1) selected.splice(found, 1);

            selected.push({
                name: select.name.replace('lens_', ''),
                value: select.value
            })
            document.dispatchEvent(event);
        });
    })

    const addToCart = document.querySelector('button[name="add-to-cart"]');
    const priceTag = document.querySelector('span.price bdi');
    addToCart.setAttribute('disabled', true);
    document.addEventListener('changelens', () => {
        if(selected.length <= parseInt(correctiveTable.getAttribute('data-count')) - 1) return;

        hiddens.forEach(hidden => {
            if(hidden.getAttribute('data-'+selected[0].name) === selected[0].value &&
                hidden.getAttribute('data-'+selected[1].name) === selected[1].value){
                addToCart.value = hidden.value;
                addToCart.removeAttribute('disabled');
                if(priceTag) priceTag.innerHTML = hidden.getAttribute('data-price');
            }
        })
    })
}