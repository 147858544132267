export function blockProductSliderScript() {
    document.addEventListener('DOMContentLoaded', function () {
        const productSliders = document.querySelectorAll('.blockProductSlider__slider');

        if (productSliders) {
            productSliders.forEach(productCardSlider => {
                const splide = new window.splide(productCardSlider, {
                    gap: '24px',
                    pagination: true,
                    arrows: true,
                    perPage: 4,
                    perMove: 4,
                    autoWidth: true,
                    breakpoints: {
                        1350: {
                            arrows: false
                        },
                        1200: {
                            perPage: 2,
                            perMove: 2,
                        },
                        767: {
                            perPage: 1,
                            perMove: 1,
                        },
                    }
                });

                splide.on('overflow', function (isOverflow) {
                    if (!isOverflow) {
                        splide.go(0);

                        splide.options = {
                            arrows: isOverflow,
                            pagination: isOverflow,
                            drag: isOverflow,
                            clones: isOverflow ? undefined : 0,
                        };
                    }
                });

                splide.mount();
            })
        }
    })
}